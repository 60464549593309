import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    areaUserInfo: null, //用户信息
    enterprises: null, //所有企业的信息 
  },
  mutations: {

    //设置用户信息
    setUserInfo(state, user){
      state.areaUserInfo = user;
    },

    //修改所的店铺
    setEnterprises(state, enterprises){
      state.enterprises = enterprises;
    },

    //修改单个店铺
    setOneEnterprise(state, enterprise){
      let i = state.enterprises.findIndex(item => item.enterprise_id === enterprise.enterprise_id);
      if(i){
        //此方法只能覆盖1层机构。针对1层以下的结构，必须写清楚完整的子结构
        //如 var a = {b: 1, c: {d:1, e: 1}}   var b ={ c: {d:'111', e: 1}}   var c = {...a, ...b} 用b去修改a.c字段， b必须要完整a.c字段，否则会变量丢失
        state.enterprises[i] = { ...state.enterprises[i], ...enterprise };
      }
    }
  },
  actions: {
    setUserInfoAsync(context, user){
      context.commit('setUserInfo', user);
    },
    setEnterprisesAsync(context, enterprises){
      context.commit('setEnterprises', enterprises);
    },
    setOneEnterpriseAsync(context, enterprise){
      context.commit('setOneEnterprise', enterprise);
    }
  },
  modules: {
  }
})


/* 
字段说明：
areaUserInfo: {
  area_info: { 
    area_id: "1",        //区域id
    area_name: "区域测试" //区域名称
  },
  area_user_id: "1"                 //用户id
  area_user_phone: "15011112222"    //用户电话
  area_user_real_name: "超级管理员"   //用户角色
  token: "49b735af6da093734193ce2c1460ac01"  //token
}
*/

/* 
字段说明：
enterprises: [
  {
    enterprise_id: '182',                    //企业id
    enterprise_name: '陕西黑猫焦化股份有限公司', //企业名称
    enterprise_name: '黑猫焦化',              //企业名称short name， 可能需要
    "enterprise_level_id": "3",           //企业风险等级id
    "enterprise_level_name": "一般风险企业" //企业风险等级名称
    "grid_info": {                //企业经纬度坐标
        "longitude": "110.4882",  //经度
        "latitude": "35.5624"     //维度
    },
    "inherent_assess_risk_level_info": {  //固有风险等级信息
        "risk_level_id_major_total": 72,    //重大风险总数
        "risk_level_id_more_total": 253,    //较大风险总数
        "risk_level_id_common_total": 406,  //一般风险总数
        "risk_level_id_low_total": 245      //低风险总数
    },
    "exist_assess_risk_level_info": {       //现有风险等级信息
        "risk_level_id_major_total": 0,       //重大风险总数
        "risk_level_id_more_total": 57,       //较大风险总数
        "risk_level_id_common_total": 142,    //一般风险总数
        "risk_level_id_low_total": 777        //低风险总数
    }
  }
]

*/