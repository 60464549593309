<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  mounted(){
    this.initUser();
  },
  methods: {
    ...mapActions(['setUserInfoAsync']),
    initUser(){
      const str = sessionStorage.getItem('area_user_info');
      const userInfo = str ? JSON.parse(str) : {};
      this.setUserInfoAsync(userInfo);
    }
  }
}

</script>
<style lang="less">
#app {
  font-family: "Alibaba-PuHuiTi-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.14rem;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0);
}

.el-message{
  font-size: 16px;
}

</style>
