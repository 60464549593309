import Vue from 'vue'
import VueRouter from 'vue-router'
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard.vue')
const Login = () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue')

//  风险
const Risk = () => import(/* webpackChunkName: "risk" */ '@/views/dashboard/smart-control/risk/Index.vue')
//隐患
const AreInformation = () => import(/* webpackChunkName: "are-information" */ '@/views/dashboard/smart-control/are-information/Index.vue')
//区域信息
const HiddenDanger = () => import(/* webpackChunkName: "hidden-danger" */ '@/views/dashboard/smart-control/hidden-danger/Index.vue')
//法律法规
const LawsRegulations = () => import(/* webpackChunkName: "laws-regulations" */ '@/views/dashboard/smart-control/laws-regulations/Index.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard', //首页大屏
    name: 'dashboard',
    component: Dashboard,
    redirect: '/dashboard/risk',
    children: [
      {
        path: 'risk',
        name: 'risk',
        component: Risk,
      },
      {
        path: 'are-information',
        name: 'are-information',
        component: AreInformation,
      },
      {
        path: 'hidden-danger',
        name: 'hidden-danger',
        component: HiddenDanger,
      },
      {
        path: 'laws-regulations',
        name: 'laws-regulations',
        component: LawsRegulations,
      }
    ]
  },
  {
    path: '/login',  //登陆
    name: 'Login',
    component: Login
  },
  {
    path: '/',   //重定向地址
    redirect: '/dashboard/risk'
  }
]

const router = new VueRouter({
  routes
})

export default router
