import '@/assets/fonts/font.css'
import '@/assets/css/style.less'

import Vue from 'vue'
import router from './router'
import store from './store'

import Fx from '@/commons/fx'
import '@/commons/interception.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'

//上线需要添加url的时候可以使用
// import { Setup } from '@/commons/fx'
// Setup({PREFIX: "/api"})

Vue.use(ElementUI);

Vue.prototype.$Fx = Fx; //将封装的axios挂载到vue的原型上 也可以在单个项目中调用

Vue.config.productionTip = false;


//使用路由守卫判断用户登陆状态，如果用户未登陆跳转到login页面
router.beforeEach((to, from, next) => {
  const isLoginPath = to.path === '/login'
  if(sessionStorage.getItem('area_user_info')){
    // 用户已登陆
    isLoginPath ? next('/') : next();
  }else{
    // 用户未登陆
    isLoginPath ? next() : next('/login');
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
