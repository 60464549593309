/*
 * Created on 2021-11-02
 * Authored by victor.liu
 */


import axios from 'axios';
import { Message } from 'element-ui';

//由于后台服务所有接口都是 post 提交formData 的方式调用。所以前端需要使用qs库，进行格式化
import qs from 'qs';

//需要排除的url，不会被qs格式化
const EXCLUDE_URL = [];

export const getUserInfo = () => {
  const tmp = sessionStorage.getItem('area_user_info');
  const userInfo = tmp ? JSON.parse(tmp) : {};
  return userInfo
}

// request拦截器
axios.interceptors.request.use(function (config) {

  const {url, method, data} = config;

  //由于后台服务所有接口都是 post 提交formData 的方式调用。所以前端需要使用qs库，进行格式化
  if ( method === 'post' && !EXCLUDE_URL.includes(url) ) {

    //组装后台需要的数据格式， 请用axios 的方法只需要传入jsonText的对象内容就行
    let params = {
      device_type: '51',
      jsonText: data ? JSON.stringify(data) : '',
    }

    //针对非登陆接口传入token， area_user_id，area_id信息，登陆成功后从 sessionStorage 中获取
    if(!url.includes('/quickLogin')){
      const {token, area_user_id, area_info: { area_id } } = getUserInfo();
      params = {
        ...params, token, area_user_id, area_id
      }
    }

    //在data当中存在数组的话需要加上{arrayFormat: 'brackets'} 否则提交时数组会显示下标
    config.data = qs.stringify(params, { arrayFormat: 'brackets' });
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});


//response 拦截器
axios.interceptors.response.use(function (response) {
  let { status, data } = response;
  //非 200情况
  if ( status != 200 ) {
    Message.error('后台接口数据异常！');
    return Promise.reject(response);
  }

  //200,但是数据异常情况
  if ( status == 200  && (data.msg !== '请求成功' || !data.data) ) {
    return Promise.reject(response);
  }

  //正常情况
  return response;

}, function (error) {
  //err
  const { response } = error;
  return Promise.reject(response);
});
