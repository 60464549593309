/*
 * Created on 2021-11-02
 * Authored by victor.liu
 */

import axios from 'axios';

let SETUP = {
  // PREFIX: ''
  //本地使用/api代理， 上线如果需要添加url前缀 在main.js中调用Setup({PREFIX: "/xxx"})方法
  PREFIX: process.env.NODE_ENV === 'development' ? '/api' : ''
};

export function Setup(options) {
  if(options) {
    SETUP = {...SETUP, ...options};
  }
}

export function URL(api) {
  return `${SETUP.PREFIX}${api}`
}

export function To(promise) {
  return promise.then(res => [res.data, null]).catch(err => [null, err]);
}

function Query(params) {
  return Object.keys(params).map(name => (params[name], `${name}=${params[name]}`)).join('&');
}

export default function Fx(api, data, opts) {
  // 默认使用POST格式
  const method = opts ? opts.method : 'POST';
  const url = URL(api);

  const instance = axios({
    url: method === 'GET' && data ? url + '?' + Query(data) : url , data, method, ...opts
  });
  return To(instance);
}

